$(document).ready(function() {
    if ($('body').data('router-name') !== 'request-index-products') {
        return;
    }

    ajaxLoadRow.initializeCKEditor();
    ajaxLoadRow.initializeSlider();

    $('[data-form-action-url]').on('submit', function(ev) {
        ev.preventDefault();
        var $form = $(this);
        var url = $form.data('form-action-url');
        var $messages = $form.find('[data-messages]');

        // TODO: aktuálně je problém, že pokud odešleme formulář, ale někde na stránce je stejný text tak se nám do textareii nezkopíruje ten co chceme, ale jiný z jiného formuláře
        ajaxLoadRow.copyEditorToTextArea();

        $.post({
            url: url,
            data: $form.serialize(),
            success: function(data) {
                if (data.message) {
                    window.alert(data.message);
                }
            }
        });
    });
});
