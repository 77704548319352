import $ from 'jquery';
window.$ = window.jQuery = $;


import Chart from 'chart.js';

require('./bootstrap');
require('jquery-ui/ui/widgets/datepicker')
require('jquery-ui/ui/widgets/sortable')
require('jquery.scrollto')
require('chosen-js/chosen.jquery.min')
require('lightslider/dist/js/lightslider.min.js');
require('simplelightbox/dist/simple-lightbox.jquery.js');
require('lazyload/lazyload.min.js');
require('./functions');
require('./functions/render-row');
require('./functions/list');
require('./aire-validator');
require('./cron/index');
require('./request/index');
require('./project/form');
require('./human-translator/toTranslate');
require('./human-translator/checkText');
require('./human-translator/toTranslateSkip');
require('./translation-text/checktext');
require('./statistics/monthlyHumanTranslator');
require('./statistics/monthlyTranslatedProject');
require('./user/edit-project-priority');
require('./user/create');
require('./user/update');
require('./ckeditor/ckeditor');
require('./main');
require('dropzone/dist/dropzone.js');
