$(document).ready(function() {
    if ($('body').data('router-name') !== 'statistics-monthly-human-translator') {
        return;
    }

    $(function() {
        var $userRows = $('[data-user-id]');

        $userRows.on('click', function() {
            var userId = $(this).data('user-id');
            var $languageRows = $('[data-languages-user-id="' + userId + '"]');

            if ($languageRows.first().is(':visible')) {
                $languageRows.hide();
            }
            else {
                $languageRows.show();
            }
        });
    });
});
