
var polarisList = ( function ( $, polarisList ) {
    'use strict';

    var $filterContent;

    polarisList.filter = function()
    {
        $filterContent = $('[data-filter-content]');

        $filterContent.find('.Polaris-Backdrop--transparent').on('click', function(ev) {
            ev.preventDefault();
            $('[data-filter-close-btn]').click();
        });

        $filterContent.find('[data-filter-close-btn]').on('click', function(ev) {
            ev.preventDefault();
            $filterContent.hide();
        });

        $filterContent.find('[data-filter-item-label]').on('click', function(ev) {
            ev.preventDefault();
            var $itemContent = $(this).closest('[data-filter-item-content]');
            var $elementsContent = $itemContent.find('[data-filter-item-element-content]');
            $elementsContent.slideToggle();
            $itemContent.find('[data-filter-item-icon-down]').toggle();
            $itemContent.find('[data-filter-item-icon-up]').toggle();
        });

        $('[data-filter-btn]').on('click', function(ev) {
            ev.preventDefault();
            $filterContent.show();
        });
    };

    polarisList.initialize = function()
    {
        polarisList.filter();

        $('[data-list-page-limit]').on('click', function(e) {
            console.log($(this).attr('data-list-page-limit'));
            var url = new URL(window.location.href);
            url.searchParams.set('limit', $(this).attr('data-list-page-limit'));
            window.location.href = url.href;
        });

        setTimeout(function() {
            var $selectRow = $('[data-grid-selecet-row]');
            if ($selectRow.length) {
                console.log($selectRow.position().top);
                console.log('a');
                $('body').scrollTo('[data-grid-selecet-row]', 1, {
                    'over': {top: -5},
                });
            }
        }, 10);


    };

    return polarisList;
}( window.jQuery, polarisList || { } ) );

window.polarisList = polarisList;
