$(document).ready(function() {
    if ($('body').data('router-name') !== 'project-create' && $('body').data('router-name') !== 'project-edit') {
        return;
    }

    var checkLevelSetting = function($this) {
        var value = $this.val();
        $('[data-custom-setting]').hide();
        if (value === 'custom') {
            $('[data-custom-setting]').show();
        }
    };

    $('[data-element-level]').on('change', function() {
        checkLevelSetting($(this));
    });
    checkLevelSetting($('[data-element-level]'));
});
