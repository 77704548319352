$(document).ready(function() {
    if ($('body').data('router-name') !== 'human-translator-translate-queue') {
        return;
    }

    window.ajaxLoadRow.initialize(toTranslateUrl, 5, toTranslateExtendLockUrl);

    $(function() {
        var $content = $('[data-ajaxloadrow-rows-content]');
        $content.delegate('[data-human-translator-skip-action]', 'click', function(ev) {
            ev.preventDefault();
            var $shipAction = $(this);
            var $textareaContent = $shipAction.closest('.phrase-edit-textarea');
            var $noShipAction = $textareaContent.find('[data-human-translator-no-skip-action]');
            var $rowContent = $shipAction.closest('[data-ajaxloadrow-row]');
            var $textarea = $textareaContent.find('textarea');
            var $skipContent = $rowContent.find('[data-human-translator-skip-content]');
            var $hidden = $('<input/>').prop('type', 'hidden').prop('name', $textarea.attr('name')).val('--skip--');
            $skipContent.append($hidden);
            $textareaContent.addClass('skip-this-text');
            $shipAction.hide();
            $noShipAction.show();
        });

        $content.delegate('[data-human-translator-no-skip-action]', 'click', function() {
            var $noShipAction = $(this);
            var $textareaContent = $noShipAction.closest('.phrase-edit-textarea');
            var $shipAction = $textareaContent.find('[data-human-translator-skip-action]');
            var $rowContent = $noShipAction.closest('[data-ajaxloadrow-row]');
            var $skipContent = $rowContent.find('[data-human-translator-skip-content]');
            var $textarea = $textareaContent.find('textarea');

            $skipContent.find('input[name="' + $textarea.attr('name') + '"]').remove();
            $shipAction.show();
            $noShipAction.hide();
            $textareaContent.removeClass('skip-this-text');
        });
    });

    $(function() {
        var $content = $('[data-ajaxloadrow-rows-content]');
        var timeoutSave = {};
        var timeoutIcon = {};

        var saveConcept = function($ckeditor) {
            var name = $ckeditor.data('ckeditor-name');
            var $box = $ckeditor.closest('[data-ajaxloadrow-row]');
            var boxId = $box.find('[data-ajaxloadrow-id]').data('ajaxloadrow-id');

            var $iconContent = $ckeditor.closest('[data-element-item-content]').find('[data-textarea-icon-content]');
            var $numberIcon = $iconContent.find('.text-part-badge');
            var $writeIcon = $iconContent.find('[data-icon-write]');
            var $saveIcon = $iconContent.find('[data-icon-save]');
            var $errorIcon = $iconContent.find('[data-icon-error]');

            if ($writeIcon.length === 0) {
                $writeIcon = $('<i/>').addClass('fas fa-pencil-alt text-muted to-translate-editor-icon').attr('data-icon-write', '');
                $iconContent.prepend($writeIcon);
            }

            if ($saveIcon.length === 0) {
                $saveIcon = $('<i/>').addClass('fas fa-save text-success to-translate-editor-icon').attr('data-icon-save', '');
                $iconContent.append($saveIcon);
            }

            if ($errorIcon.length === 0) {
                $errorIcon = $('<i/>').addClass('fas fa-times text-danger to-translate-editor-icon').attr('data-icon-error', '');
                $iconContent.append($errorIcon);
            }
            $writeIcon.show();
            $saveIcon.hide();
            $errorIcon.hide();
            $numberIcon.css({'opacity': '0'});

            clearTimeout(timeoutSave[name]);
            clearTimeout(timeoutIcon[name]);
            timeoutSave[name] = setTimeout(() => {
                var editor = $ckeditor.data('editor');
                var text = editor.getData();

                // budeme raději odesílat pomocí GET, protože by nám mohla vypršet platnost CSRF tokenu pokud bude překládat dlouho
                $.ajax({
                    url: '/admin/to-translate/save-concept/',
                    method: 'GET',
                    data: {
                        'id': boxId,
                        'name': name,
                        'text': text,
                    },
                    success: function(data) {
                        if (data.status === 'success') {
                            $numberIcon.css({'opacity': '0'});
                            $writeIcon.hide();
                            $errorIcon.hide();
                            $saveIcon.show();
                            timeoutIcon[name] = setTimeout(function() {
                                $saveIcon.hide();
                                $writeIcon.hide();
                                $errorIcon.hide();
                                $numberIcon.css({'opacity': '1'});
                            }, 1000);
                        } else if (data.status === 'error') {
                            $numberIcon.css({'opacity': '0'});
                            $writeIcon.hide();
                            $errorIcon.show();
                            $saveIcon.hide();
                            timeoutIcon[name] = setTimeout(function() {
                                $saveIcon.hide();
                                $writeIcon.hide();
                                $errorIcon.hide();
                                $numberIcon.css({'opacity': '1'});
                            }, 3000);
                        }
                    },
                    error: function() {
                        $numberIcon.css({'opacity': '0'});
                        $writeIcon.hide();
                        $errorIcon.show();
                        $saveIcon.hide();
                        timeoutIcon[name] = setTimeout(function() {
                            $saveIcon.hide();
                            $writeIcon.hide();
                            $errorIcon.hide();
                            $numberIcon.css({'opacity': '1'});
                        }, 3000);
                    }
                });
            }, 2000);
        };

        $content.delegate("[data-translator-dictionary-item]", 'click', function() {
            var $ckeditor = $(this).closest('[data-element-item-content]').find('.ckeditor');
            saveConcept($ckeditor);
        });

        $content.delegate(".ckeditor", 'keyup', function() {
            saveConcept($(this));
        });


        $content.delegate('[data-concept-drop-action]', 'click', function(ev) {
            ev.preventDefault();

            var name = $(this).data('concept-drop-action');
            var $box = $(this).closest('[data-ajaxloadrow-row]');
            var boxId = $box.find('[data-ajaxloadrow-id]').data('ajaxloadrow-id');
            var editor = $(this).closest('[data-element-item-content]').find('[data-ckeditor-name]').data('editor');

            $.ajax({
                url: '/admin/to-translate/drop-concept/',
                method: 'GET',
                data: {
                    'id': boxId,
                    'name': name,
                },
                success: function(data) {
                    console.log(editor);
                    editor.setData(data.originalText);
                },
                error: function() {

                }
            });
        });


    });

    $(function() {
        var $content = $('[data-ajaxloadrow-rows-content]');

        function getSelectedText(editor)  {
            var range = editor.model.document.selection.getFirstRange();
            for (const item of range.getItems()) {
                if (item.data) {
                    return item.data;
                }
            }
            return '';
        }

        function getSelectedTextIndex(editor)  {
            var range = editor.model.document.selection.getFirstRange();
            for (const item of range.getItems()) {
                if (item.data) {
                    return item.offsetInText;
                }
            }
            return '';
        }

        $(document).keydown(function(e) {
            var $editorElement = $('.ck-focused');

            if( e.which === 81 && e.ctrlKey && e.shiftKey && $editorElement.length === 1){
                var $btn = $editorElement.closest('[data-ajaxloadrow-row]').find('[data-target="#translatorDictionaryModal"]');
                var editor = $editorElement.data('editor');
                var selectedText = getSelectedText(editor);
                var selectedTextIndex = getSelectedTextIndex(editor);

                $btn.data('last-editor-index', selectedTextIndex);
                $btn.data('last-editor-name', $editorElement.data('ckeditor-name'));
                $btn.data('text-source', selectedText);
                $btn.trigger('click');
            }
        });

        $content.delegate('[data-translator-dictionary-item]', 'click', function() {
            var $translatorDictionaryContent = $(this).closest('[data-translator-dictionary-item]');
            var $elementContent = $(this).closest('[data-element-item-content]');
            var $editor = $elementContent.find('[data-ckeditor-name]').data('editor');

            var text = $editor.getData();
            var from = $translatorDictionaryContent.find('.text-source').data('text');
            var to = $translatorDictionaryContent.find('.text-target').data('text');

            if ($translatorDictionaryContent.is('.disabled') && to && text.indexOf(to) !== -1) {
                text = text.replaceAll(to, from);
                $translatorDictionaryContent.removeClass('disabled');
                $translatorDictionaryContent.attr('data-original-title', $translatorDictionaryContent.attr('data-title-active')).tooltip('show');
            } else if (text.indexOf(from) !== -1) {
                text = text.replaceAll(from, to);
                $translatorDictionaryContent.addClass('disabled');
                $translatorDictionaryContent.attr('data-original-title', $translatorDictionaryContent.attr('data-title-disabled')).tooltip('show');
            }
            $editor.setData(text);
        });

        $content.delegate('[data-target="#translatorDictionaryModal"]', 'click', function(ev) {
            ev.preventDefault();

            var $rowContent = $(this).closest('[data-ajaxloadrow-row]');
            var $modalForm = $('[data-translator-dictionary-form]');

            var $formElementId = $modalForm.find('[data-translator-dictionary-form-id]');
            var $formElementType = $modalForm.find('[data-translator-dictionary-form-type]');
            var $formElementTextFrom = $modalForm.find('[data-translator-dictionary-form-text-from]');
            var $formElementTextTo = $modalForm.find('[data-translator-dictionary-form-text-to]');

            $formElementId.val('');
            $formElementTextFrom.val('');
            $formElementTextTo.val('');
            $formElementType.val($formElementType.find('option').first().attr('value'));
            $modalForm.find('[data-alert-content]').empty();

            if ($(this).data('text-source')) {
                $formElementTextFrom.val($(this).data('text-source'));
                setTimeout(function() {
                    $formElementTextTo.focus();
                }, 500);

                $(this).data('text-source', '');
            } else {
                $rowContent.find('[data-ckeditor-name]').each(function(i, el) {
                    var selectedText = getSelectedText($(el).data('editor'));
                    if (selectedText) {
                        $formElementTextFrom.val(selectedText);
                        $formElementTextTo.focus();
                        return false;
                    }
                });
            }

            $formElementId.val($rowContent.find('[data-ajaxloadrow-id]').data('ajaxloadrow-id'));
        });
/*
        $("#translatorDictionaryModal").on("hidden.bs.modal", function (ev) { // remove the event listeners when the dialog is dismissed
            ev.preventDefault();

            setTimeout(function() {
                console.log($('[data-ckeditor-name="phrases[12579]"]').data('editor'));
                $('[data-ckeditor-name="phrases[12579]"]').data('editor').focus();
            }, 1000);
        });

        $("#translatorDictionaryModal").on("hide.bs.modal", function (ev) { // remove the event listeners when the dialog is dismissed
            //ev.preventDefault();
            $("#translatorDictionaryModal a.btn").off("click");
            setTimeout(function() {
                console.log($('[data-ckeditor-name="texts[11535]"]').data('editor'));
                $('[data-ckeditor-name="texts[11535]"]').data('editor').focus();
            }, 1000);
        });
*/
        $('[data-translator-dictionary-form]').on('blur submit', function(ev) {
            ev.preventDefault();

            var $form = $(this);
            var $alertContent = $form.find('[data-alert-content]');
            var $modal = $form.closest('#translatorDictionaryModal');

            $.ajax({
                url: '/admin/translator-dictionary/add',
                method: 'GET',
                data: $form.serialize(),
                success: function(data) {
                    ev.preventDefault();
                    if (data.status === 'success') {
                        $modal.modal('hide');

                        var $rowContent = $('[data-ajaxloadrow-id="' + data.queue_translate_using_human_translator_id + '"]').closest('[data-ajaxloadrow-row]');

                        var from = data.text_from;
                        var to = data.text_to;

                        var $item = $('<div/>');
                        $item.addClass('translator-dictionary-row Polaris-Tag Polaris-Tag--clickable mb-1');
                        $item.attr('data-translator-dictionary-item', '');

                        $item.append($('<div/>').addClass('text-source').attr('data-text', from).text(from));
                        $item.append('&nbsp;');
                        $item.append($('<i/>').addClass('icon icon-ChevronRightMinor text-muted'));
                        $item.append('&nbsp;');
                        $item.append($('<div/>').addClass('text-target').attr('data-text', to).text(to));

                        $('[data-ckeditor-name]').each(function(i, el) {
                            var $editor = $(el).data('editor');
                            var $textarea = $(el).closest('.input-group').find('textarea');
                            var $content = $(el).closest('[data-element-item-content]').find('[data-translator-dictionary-content]');

                            if ($editor && $editor.getData().indexOf(from) !== -1) {
                                $content.show();

                                var $itemNew = $item.clone();
                                $content.append($itemNew);

                                // jen pokud se jedná o box v kterém zrovna zadávám slovník, tak jej hned zaklikneme
                                if ($(el).closest('[data-ajaxloadrow-row]').find('[data-ajaxloadrow-id="' + data.queue_translate_using_human_translator_id + '"]').length) {
                                    $itemNew.click();
                                }
                            }
                            // ckeditory se načítají pomocí lazyloadu, takže když nemáme odscrolovanou celou stránku, tak tam ještě nejsou, proto to budeme hledat v textaree
                            else if (typeof $editor == 'undefined' && $textarea.val().indexOf(from) !== -1) {
                                $content.show();
                                $content.append($item.clone());
                            }
                        });

                        var editor = $('[data-ckeditor-name="' + $rowContent.find('[data-target="#translatorDictionaryModal"]').data('last-editor-name') + '"]').data('editor');
                        editor.focus();


                        // TODO: ideální by bylo ho vrátit tam kde skončil, ale nevím jak
                        //console.log($rowContent.find('[data-target="#translatorDictionaryModal"]').data('last-editor-index'));
                        //editor.model.change( writer => {
                            //writer.setSelection( writer.createPositionAt( editor.model.document.getRoot(), 'end' ));
                        //});
                    }
                    else if (data.status === 'error') {
                        $alertContent.empty();
                        $alertContent.append($('<div/>').addClass('alert alert-danger').html(data.message));
                    }
                },
                error: function() {
                    $alertContent.empty();
                    $alertContent.append('Send request fail');
                }
            });
        })
    });
});
