$(document).ready(function() {
    if ($('body').data('router-name') !== 'user-edit-project-priority') {
        return;
    }

    $( function() {
        $('[data-project-all-content], [data-project-sortable-content]').sortable({
            connectWith: "[data-project-connectedSortable]",
            stop: function( event, ui ) {
                var $input = $('[data-project-ids]');
                var sortedProjectIds = [];
                $('[data-project-sortable-content]').find('[data-project-id]').each(function(i, el) {
                    sortedProjectIds.push($(el).data('project-id'));
                });
                console.log(sortedProjectIds);
                $input.val(sortedProjectIds.join('|'));
            }
        });

    } );
});
