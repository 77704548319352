$(document).ready(function() {
    if ($('body').data('router-name') !== 'statistics-monthly-translated-project') {
        return;
    }

    $(function() {
        var $projectRows = $('[data-project-id]');

        $projectRows.on('click', function() {
            var projectId = $(this).data('project-id');
            var $languageRows = $('[data-languages-project-id="' + projectId + '"]');

            if ($languageRows.first().is(':visible')) {
                $languageRows.hide();
            }
            else {
                $languageRows.show();
            }
        });
    });
});
