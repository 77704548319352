
window.tooltipStart = function() {
    $('.tooltip').remove();
    $('[data-toggle="tooltip"]').tooltip();
};

window.datepicker = function() {
    $('.datepicker').datepicker({
        dateFormat: "yy-mm-dd"
    });
};

window.refreshMenuCount = function() {
    var $content = $('[data-menu-content]');
    if ($content.length === 0) {
        return false;
    }

    $.ajax({
        url: '/admin/tool/menu/refresh-count',
        success: function(data) {
            var $countItems = $content.find('.nav-item-badge');
            $countItems.each(function(i,el) {
                var $el = $(el);
                var key = $el.closest('[data-menu-key]').data('menu-key');

                if (typeof data[key] === 'undefined') {
                    $el.text(0);
                }
                else {
                    $el.text(data[key]);
                }
            });
        }
    });
};

window.alert = function(message) {
    var $toast = $('#sampleToast');
    $toast.find('.frame-toast-content').text(message);
    $toast.addClass('show');
    setTimeout(function () {
        $toast.removeClass('show');
    }, 5000)
};

window.elasticElements = function() {
    $('textarea.js-elastic').each(function(index, element) {
        var elasticElement = element,
            $elasticElement = $(element),
            initialHeight = initialHeight || $elasticElement.height(),
            delta = parseInt( $elasticElement.css('paddingBottom') ) + parseInt( $elasticElement.css('paddingTop') ) || 0,
            resize = function() {
                $elasticElement.height(initialHeight);
                $elasticElement.height( elasticElement.scrollHeight - delta );
            };

        $elasticElement.on('input change keyup', resize);
        resize();
    });

    var autoResize = {
        minWidth: 30,
        maxWidth: 800,
        buffer: 10,

        resize: function(el) {
            var test = document.createElement('pre');
            test.className = 'input-test';
            test.innerHTML = el.value;
            el.parentNode.appendChild(test);
            el.style.width = Math.min(Math.max(test.offsetWidth + this.buffer, this.minWidth), this.maxWidth) + 'px';
            el.parentNode.removeChild(test);
        },

        init: function() {
            var els = document.getElementsByClassName('js-elastic'),
                i = els.length;

            while (i--) {
                els[i].addEventListener('keydown', function() {
                    autoResize.resize(this);
                }, false);

                els[i].addEventListener('keyup', function() {
                    autoResize.resize(this);
                }, false);

                this.resize(els[i]);
            }
        }
    };
    autoResize.init();
};

window.activeLazyload = function(){
	lazyload(document.querySelectorAll(".lazyload"));
	$(".lazyload").removeClass('lazyload');
};
