$(document).ready(function() {
    if ($('body').data('router-name') !== 'user-edit') {
        return;
    }

    // zobrazeni formuláře na základě zvolených dat
    $(function () {
        console.log('test');
        $('[data-change-password]').on('change', function() {
            if ($('[data-change-password]').is(':checked')) {
                $('[data-change-password-content]').show();
            }
            else {
                $('[data-change-password-content]').hide();
            }
            $('#password').val('');
            $('#password_confirmation').val('');
        });
    });
});
