$(document).ready(function() {
    if ($('body').data('router-name') !== 'user-create') {
        return;
    }

    // zobrazeni formuláře na základě zvolených dat
    $(function () {
        var $form = $('[data-user-create-edit-form]');
        if ($form.length === 0) {
            return;
        }

        var $row_language_scopes = $('[data-language-scopes-content]');
        var $row_roles = $('[data-aire-for="roles"]');
        var $row_text_type_translate = $('[data-text_type_translate]');
        var $element_group = $('#group');

        var renderFormByValue = function() {
            var isTranslater = $element_group.val() === 'translator';
            var isClient = $element_group.val() === 'client';

            if (isClient) {
                $row_text_type_translate.show();
            }
            else {
                $row_text_type_translate.hide();
                $row_text_type_translate.find('input').prop('checked', false);
            }


            if (isTranslater) {
                $row_language_scopes.show();
                $row_roles.show();
                $(".chosen-select").chosen();
            }
            else {
                $row_language_scopes.hide();
                $row_roles.hide(); $row_roles.val('');
            }

        };
        $form.find('input, select').on('click change', function () {
            renderFormByValue();
        });
        renderFormByValue();
    });
});
