$(document).ready(function() {
    if ($('body').data('router-name') !== 'cron-index') {
        return;
    }

    $(function() {

        var updateData = function() {
            var $waitSpan = $('[data-wait]');
            var $lastRun = $('[data-last-run]');
            var $cronLogContent = $('[data-cron-log-content]');

            $.ajax({
                url: '/admin/cron/ajax',
                success: function(data) {
                    $waitSpan.each(function(i, el) {
                        var name = $(el).data('wait');
                        $(el).html(data.wait[name]);
                    });

                    $lastRun.each(function(i, el) {
                        var name = $(el).data('last-run');
                        $(el).html(data.lastRun[name]);
                    });

                    $waitSpan.each(function(i, el) {
                        var name = $(el).data('wait');
                        $(el).html(data.wait[name]);
                    });

                    $cronLogContent.empty();
                    $.each(data.cronLogs, function(key, value) {
                        $row = $('<tr/>');
                        $row.append($('<td/>').text(value.created_at));
                        $row.append($('<td/>').text(value.cron));
                        $row.append($('<td/>').text(value.processed_items));
                        $cronLogContent.append($row);
                    });
                }
            });
        };
        updateData();
        setInterval(function () {
            updateData();
        }, 20000);

        $('[data-cron-content]').delegate('[data-cron-link]', 'click', function(ev) {
            ev.preventDefault();
            var $a = $(this);
            var $li = $a.closest('li');
            var url = $a.attr('href');
            var $status = $a.closest('td').find('[data-cron-status]');
            var $messages = $('.messages');

            var addStatus = function(icon) {
                $status.html(icon);
                setTimeout(function () {
                    $status.find('i').fadeOut();
                }, 1000);
            };

            $status.html('<i class="fas fa-spinner"></i>');
            $.ajax({
                url: url,
                success: function(data) {
                    if (typeof data.status !== 'undefined' && data.status === 'success') {
                        addStatus('<i class="fas fa-check fa-24 text-success"></i>');
                    }
                    else {
                        addStatus('<i class="fas fa-times fa-24 text-danger"></i>');
                        if (typeof data.message !== 'undefined') {
                            $messages.empty().append($('<div/>').addClass('alert alert-danger').text(data.message));
                        }
                    }
                    updateData();
                },
                error: function() {
                    addStatus('<i class="fas fa-times fa-24 text-danger"></i>');
                    $messages.empty().append($('<div/>').addClass('alert alert-danger').text('Response error'));
                }
            });
        })
    });
});
