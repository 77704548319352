

$(document).ready(function() {
    window.tooltipStart();
    window.datepicker();

    $('[data-tabs] a').on('click', function (e) {
        e.preventDefault();
        $(this).tab('show');
    });



    $('[data-login-as-user-select]').on('change', function () {
       window.location.href = '/admin/user/' + $(this).val() + '/login-as-user/';
    });

    $(".chosen-select").chosen();

    window.elasticElements();
    window.polarisList.initialize();
});

$(function() {
    // whenever we hover over a menu item that has a submenu
    $('.nav-main .nav-item').on('mouseover', function() {
        var $menuItem = $(this),
            $submenuWrapper = $('> .nav', $menuItem);

        if($submenuWrapper.length === 0) return;

        // grab the menu item's position relative to its positioned parent
        var menuItemPos = $menuItem.position();

        // place the submenu in the correct position relevant to the menu item
        $submenuWrapper.css({
            top: menuItemPos.top,
            left: menuItemPos.left + Math.round($menuItem.outerWidth())
        });
    });

    $('[data-menu-open]').on('click', function(ev) {
        ev.preventDefault();
        var url = $(this).attr('href');
        var value = $('body').is('.nav-small');
        $.ajax({
            'url': url,
            'data': {
                'category': 'menu',
                'value': value ? 1 : 0,
            },
            success: function() {
                $('body').toggleClass('nav-small');
                $(window).trigger('resize'); // fix pro pregenerovani velikosti slideru fotek
            }
        });
    });

    activeLazyload();

    $('.input-password-toggle').on('click', function(){
        var input = $(this).parent().find('#password');
        if (input.attr("type") == "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });

    $('.frame-toast-close').on('click', function(){
        $('.frame-toast').removeClass('show');
    })
});
